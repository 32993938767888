import { defineComponent, reactive, onMounted, ref, getCurrentInstance, ComponentInternalInstance } from 'vue'
import { useRouter } from 'vue-router'
import { 
    listByStatus,
    finishList,
    listByRangeStatus
} from '@/api/index'
import { delay, ages } from '@/utils/index'
import { SET_RESUME } from '@/utils/constant'

import './index.less'

export default defineComponent({
    name: 'Resume',
    setup() {
        //const { appContext } = getCurrentInstance() as ComponentInternalInstance
       // const proxy = appContext.config.globalProperties
        const router = useRouter()

        const job = reactive({
            data: [],
            pageNum: 0,
            pageSize: 10,
            totalPage: 1,
            totalRecord: 0,
            next: true,
            noData: false,
            tabs: [
                {id: 1, title: '待处理简历', checked: true, val: 0},
                {id: 2, title: '已入职', checked: false, val: 400},
                {id: 3, title: '已淘汰', checked: false, val: 10000}
            ],
            tabsIndex: 1
        })
        const loading = ref(false)
        const finished = ref(false)

        onMounted(() => {
            _listByRangeStatus()
           onCheckData()
        })

        const onCheckData = () => {
            delay(() => {
              job.data.length > 0 ?  job.noData = false : job.noData = true
            },500)
        }

        const _listByRangeStatus = async (limit = 10, start = 0) => {
            if (job.data.length >= job.totalRecord && job.data.length !== 0) {
                //加载状态结束
                console.log("这里执行了。。。。")
                loading.value = false 
                finished.value = true
                return 
            }
            job.next = false
            const res: any = await listByRangeStatus({ start,limit, data: {from: 0 , to: 400}})

            let timer:any = setTimeout(() => {
                clearTimeout(timer)
                timer = null 
                job.data = job.data.concat(res.data);
                job.pageNum = res.pageNum 
                job.pageSize = res.pageSize
                job.totalPage = res.totalPage
                job.totalRecord = res.totalRecord
                job.next = true
                job.data.length > 0 ? job.noData = false : job.noData = true
            }, 500)
        }

        const _finishList = async (limit = 10, start = 0) => {
            if (job.data.length >= job.totalRecord && job.data.length !== 0) {
                //加载状态结束
                console.log("这里执行了。。。。")
                loading.value = false 
                finished.value = true
                return 
            }
            job.next = false
            const res: any = await finishList({ start,limit})

            let timer:any = setTimeout(() => {
                clearTimeout(timer)
                timer = null 
                job.data = job.data.concat(res.data);
                job.pageNum = res.pageNum 
                job.pageSize = res.pageSize
                job.totalPage = res.totalPage
                job.totalRecord = res.totalRecord
                job.next = true
                job.data.length > 0 ? job.noData = false : job.noData = true
            }, 500)
        }

        const _listJob = async(limit = 10, start = 0, status = 0) => {
            
            if (job.data.length >= job.totalRecord && job.data.length !== 0) {
                //加载状态结束
                console.log("这里执行了。。。。")
                loading.value = false 
                finished.value = true
                return 
            }
            job.next = false
            const res: any = await listByStatus({ start,limit, data: status })

            let timer:any = setTimeout(() => {
                clearTimeout(timer)
                timer = null 
                job.data = job.data.concat(res.data);
                job.pageNum = res.pageNum 
                job.pageSize = res.pageSize
                job.totalPage = res.totalPage
                job.totalRecord = res.totalRecord
                job.next = true
                job.data.length > 0 ? job.noData = false : job.noData = true
            }, 500)

            console.log(res, '招聘职位.')
        }

        const onLoad = () => {
            //debugger

            
            // 数据全部结束
            if (job.data.length >= job.totalRecord) {
                finished.value = true
                //加载状态结束
                loading.value = false 
                console.log('进来了啊。')
            }
           
            let timer: any = setTimeout(() => {
                clearTimeout(timer)
                timer = null
                
                //加载状态结束
                loading.value = true 
                // 数据全部结束
                if (job.data.length >= job.totalRecord && job.data.length != 0) {
                    loading.value = false 
                    finished.value = true
                }

                if (!job.next)return
                const start = (job.pageNum + 1) * job.pageSize - job.pageSize

                const jobtabs = job.tabs.filter(ite => ite.checked)
                if (jobtabs.length > 0 && jobtabs[0].id === 1) {
                    _listByRangeStatus(10, start)
                } else if (jobtabs.length > 0 && jobtabs[0].id === 1) {
                    _finishList(10, start)
                } else if (jobtabs.length > 0 && jobtabs[0].id === 1) {
                    _listJob(10, start)
                }

                
                

            },1000)
        }
        
        //默认组件
        const defaultUI = () => {
            return (
                <div class="default-container">
                    <img class="default-pic" src="https://img.fanwoon.com/nothing_icon.png" />
                    <div class="publish-slogan">
                        {
                            job.tabsIndex === 1 ? '无待处理简历' : job.tabsIndex === 2 ? '无已入职简历' : job.tabsIndex === 3 ? '无已淘汰简历' : ''
                        }
                    </div>
                </div>
            )
        }

        const getAges = (str: string) => {
            return ages(str)
        }

        const onInfo = (item: any) => {
            localStorage.setItem(SET_RESUME, JSON.stringify(item))
           const state = job.tabsIndex === 1 ? 0 : job.tabsIndex === 2 ? 400 : job.tabsIndex === 3 ? 10000 : ''
           router.push({path: `/resumeInfo`, query: { jobId: item.jobId, state: state, id: item.id } })
        }

        const resumeListUI = () => {
            return (
                <van-list 
                        v-model={ loading.value } 
                        finished={ finished.value }
                        finished-text={ job.data.length === 0 ? '' : '' }
                        onLoad={ onLoad }
                        >
                            {
                                job.data.map((item: any) => {
                                return (
                                    <div key={item.id} class='fw-resume-item-bar' onClick={() => onInfo(item)}>
                                            <div class='fw-resume-header'>
                                                <div class='fw-avatar' style={{
                                                    background: `url(${item.candidateInfo.imgUrl}) no-repeat`,
                                                    backgroundSize: `100% 100%`
                                                }}></div>
                                                <div class='fw-infos'>
                                                    <div class='fw-jobs-bars'>
                                                        <label class='fw-name'>{item.candidateInfo.name}</label>
                                                        <span>{getAges(item.candidateInfo.birth)}岁</span> ｜ 
                                                        <span>{item.candidateInfo.gender === 0 ? '女' : '男'}</span> ｜ 
                                                        <span>{item.candidateInfo.startWork}年工作经验</span> ｜ 
                                                        <span>{item.candidateInfo.education === '不限'? '学历不限' : item.candidateInfo.education}</span>
                                                    </div>
                                                    <div class='fw-phone'>
                                                        联系方式：
                                                        {
                                                            !item.payStatus ? 
                                                            (
                                                                <span class='mark-box'><img src='https://img.fanwoon.com/jiesuo%402x.png' alt=''/><span>邀约面试解锁联系方式</span></span>
                                                            ) : item.candidateInfo.phone
                                                        }
                                                    </div>
                                                    <div>{item.companyName}-{item.jobName}</div>
                                                    <div class='fw-right-icons'><van-icon color="#333" name="arrow" /></div>
                                                </div>
                                            </div>
                                            <div class='fw-job-status-area-bar'>
                                                <div>应聘：{item.jobName && item.jobName.length > 8 ? item.jobName.substring(0, 8) +'...' : item.jobName}</div>
                                                <div class={`fw-status-box ${[3].includes(job.tabsIndex) ? 'fw-out' : ''}`}>
                                                    {
                                                        [1, 2].includes(job.tabsIndex) ? (
                                                            <div class='fw-status-parent'>
                                                                <ul class='fw-status-bar-item'>
                                                                    <li class={`fw-item ${[1, 2].includes(job.tabsIndex) ? 'fw-active-square' :''}`}></li>
                                                                    <li class={`fw-item ${[2].includes(job.tabsIndex) ? 'fw-active-square' :''} ${item.status === 200 ? 'fw-active-square' : ''} ${item.status === 400 && !item.finish ? 'fw-active-square' : ''}`}></li>
                                                                    <li class={`fw-item ${[2].includes(job.tabsIndex) ? 'fw-active-square' :''}`}></li>
                                                                </ul>
                                                                <div class='fw-status-des'>
                                                                    <span>邀约</span>
                                                                    <span>面试</span>
                                                                    <span>入职</span>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div class='fw-out-box'>
                                                                <div class='fw-out-square'></div>
                                                                <span>已淘汰</span>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                     )
                                })
                            }
                    </van-list>
            )
        }

        const onReset = () => {
            job.data = []
            job.pageNum = 0 
            job.pageSize = 10
            job.totalPage = 0
            job.totalRecord = 0
        }

        const onChangeTag = (item: any) => {
            onReset()
            job.tabsIndex = item.id 

            job.tabs.forEach((ite) => {
                if (ite.id === item.id) {
                    ite.checked = true
                } else {
                    ite.checked = false
                }
            })

            if (item.id === 1) {
                _listByRangeStatus()
            } else if (item.id === 2) {
                _finishList(10, 0)
            } else {
                _listJob(10, 0, item.val)
            }
        }

        const HeaderUI = () => {
            return (
                <div class='fw-header-box'>
                    {
                        job.tabs.map((ite, index) => {
                            return <div 
                                key={index} 
                                class={`fw-header-item`}
                                >
                                    <div onClick={() => onChangeTag(ite)} class={`fw-items ${ite.checked ? 'fw-header-active-item' : ''}`}>{ite.title}</div>
                                </div>
                        })
                    }
                </div>
            )
        }

        return () => (
            <div class="resume-page">
                <div>
                    <div class="header-box flex-box justify-content-between">
                        <div>查看简历</div>
                        <img class="logo" src="https://img.fanwoon.com/new_logo.png" />
                    </div>
                    <HeaderUI />
                </div>
                <div class="container-box">
                    <div>
                       { job.data.length === 0 && job.noData ? <defaultUI />  : '' }
                       { job.data.length > 0 ? <resumeListUI /> : '' }
                    </div>
                </div>
            </div>
        )
    }
})